<template>
  <div>
    <div
      v-if="content.widgetTop.progress.val !== null"
      class="sticky"
    >
      <v-progress-linear
        v-tooltip="$t('widgetTop.progress.tooltip', {percent: content.widgetTop.progress.val})"
        :value="content.widgetTop.progress.val"
        theme="top"
      />
    </div>

    <div
      v-resize="debounce(onResize)"
      class="container my-24 my-lg-32"
      :class="{'user-select-none': service.restrictions.doTextCopy}"
    >
      <div
        v-if="content.header && !PCS_data_isShow"
        class="row mb-24"
      >
        <header class="col">
          <h2 v-html="content.header"/>
        </header>
      </div>

      <div
        :class="classes"
        class="processing"
      >
        <div
          v-if="content.widget.isShown && !PCS_data_isShow"
          class="processing__widget"
        >
          <l-widget
            processing
            :show-container="!!content.widget.progress.total"
          >
            <template #header>
              <w-timer
                v-if="content.widget.timer.start !== null || content.widget.timer.end !== null"
                :start="content.widget.timer.start"
                :end="content.widget.timer.end"
                :server-time="content.widget.timer.current"
                @end="sendSubmitContent({
                  time_out: true,
                })"
              />
            </template>

            <template #default>
              <w-progress
                v-if="content.widget.progress"
                :progress="content.widget.progress"
              />
            </template>
          </l-widget>
        </div>

        <div class="processing__content">
          <processing-check
              v-if="PCS_data_isShow"
              :content="PDC_data_content"
              @send="PDC_method_setPassed"
          ></processing-check>

          <template v-else>
            <component
              v-for="render in content.render"
              :key="`block__${render.content.code || render.type}`"
              :is="`Processing${capitalize(convertSnakeToCamel(render.type))}`"
              :content="render.content"
              :request="render.request"
              @send="sendContent"
            />
          </template>
        </div>
      </div>
    </div>

    <r-restriction/>

    <v-modal
      v-model="isModalShown"
      :clickOutsideToClose="false"
    >
      <component
        v-for="modalRender in service.modal.render"
        :key="`modal-block__${modalRender.content.code}`"
        :is="`Processing${capitalize(modalRender.type)}`"
        :content="modalRender.content"
        isModal
        @close="hideModal"
      />
    </v-modal>
  </div>
</template>

<script>
import { getContentAjaxApi, getContentApi, sendContentApi } from '@/api'
import { capitalize } from '@/filters'
import { convertHexToRgb, convertSnakeToCamel, convertToFormData, debounce, getType } from '@utils/helpers'
import {
  ProcessingCheck,
  ProcessingContentBlocks,
  ProcessingContentTable,
  ProcessingCopyableLink,
  ProcessingHeader,
  ProcessingHtml,
  ProcessingInput,
  ProcessingLine,
  ProcessingLink,
  ProcessingMedia,
  ProcessingProcess51,
  ProcessingRequestButton,
  ProcessingRespondentInfoLine,
  ProcessingScreen360,
  ProcessingSubmit
} from '@views/Processing'
import {
  ProcessingDynamicContentMixin,
  ProcessingMenageSessionMixin,
  ProcessingCheckScreenMixin
} from '@views/Processing/mixins'
import { VButton, VCard, VModal, VProgressLinear } from '@components/base'
import { WProgress, WTimer } from '@components/widgets'
import { RRestriction } from '@components/rednderless'
import Resize from '@directives/resize'
import { LWidget } from '@/layouts'
import { mapActions, mapGetters } from 'vuex'
import { DEFAULT_LOGO_KEY } from '@constants/components'
import { Frameable } from '@mixins'
import { Tooltip } from '@directives'
import { ShowMessageOnTimer } from '@/services'
import { i18n } from '@/i18n'

/**
 * Снэкбар показывающий уведомление о необходимости перезагрузить приложение
 * @type {ShowMessageOnTimer}
 */
const showReloadMessage = new ShowMessageOnTimer(
  i18n.t('site.error.reloadPage'),
  20000, // 20 сек
  {
    endless: true,
    color: 'danger'
  }
)

/**
 * ###Параметры варианта
 * @typedef {Object} VariantOption
 * @property {string} code - id вопроса
 * @property {string} text - текст вопроса *
 * @property {string=} hint_text - дополнительный текст вопроса *
 * @property {string=} excludingAll - вариант Включить всё
 * @property {string=} includingAll - вариант Исключить всё
 * @property {string=} ignoreIncludesAll - Параметр варианта ответа. который не даёт выбрать вариант ответа через includingAll.
 *
 * Описание получаемых данных
 * @property {object[]} - получаемые данные
 * @property {string } type - тип получаемых данных
 * @property {string | object} content - значения получаемых данных
 *
 * type === title - заголовок сайта
 * @property {string} content - текст заголовка
 *
 * type === header - заголовок страницы
 * @property {string} content - текст заголовка
 *
 * type === html - свободный html
 * @property {string} content - html текст
 *
 * type === router - страница со списком тестов (Разделы)
 * @property {Object[]} content.links  - массив с описанием теста
 * @property {boolean} content.links[].link_active - активен, или нет
 * @property {string} content.links[].link_button_text - текст кнопки
 * @property {string} content.links[].link_block - индификатор для запроса
 * @property {string} content.links[].link_text - название теста
 * @property {string} content.links[].link_time - отводимое время
 * @property {string} content.text - html текст инструкции
 *
 * type === timer - таймер, если он присутствует
 * @property {timestamp} content.current - текущее время на сервере
 * @property {timestamp} content.start - время начала теста
 * @property {timestamp} content.end - время конца теста
 *
 * type === progress - сколько заданий пройдено
 * @property {number} content.in - всего вопросов
 * @property {number} content.val - текущий вопрос
 * @property {number} content.text - текст виджета
 *
 * type === check - проверка доступности
 *
 * type === submit - кнопка ответа
 * @property {string} content - текст кнопки ответа
 *
 * content.type === submit && content.subtype === 'clipboard' - кнопка копирования текущей ссылки в буфер обмена
 * @property {string} content.type - тип поля
 * @property {string} content.subtype - подтип поля
 * @property {string} content.text - текст кнопки
 *
 * type === widget - состояние виджета
 * @property content {show} - показывать или скрывать
 *
 * type === link_to_other_session - ссылка на другую сессию
 * @property {boolean} redirect - переход автоматически (не используется)
 * @property {string} session_uuid - ссылка
 * @property {string} text - текст ссылки
 *
 * type === input - поле ввода
 * @property {string} content.type - тип поля
 * @property {string} content.subtype - подтип поля
 *
 * content.type === radio - radio с плашками
 * content.type === radio && content.subtype === 'media' && content.media === 'url' - кнопка с картинкой
 * content.type === radio && content.subtype === 'media' && content.media === '' - плашка с перемычкой
 *
 * content.type === checkbox - checkbox с плашками
 * content.type === checkbox && content.subtype === 'media' && content.media === 'url' - кнопка с картинкой
 * content.type === checkbox && content.subtype === 'media' && content.media === '' - плашка с перемычкой
 *
 * content.type === select && content.multiple === false - одиночный селект без поиска
 * content.type === select && content.multiple === true - множественный селект без поиска
 * content.type === select && content.multiple === false && content.subtype === 'combo' - одиночный селект с возможностью поиска
 * content.type === select && content.multiple === true && content.subtype === 'combo' - множественный селект с возможностью поиска
 *
 * content.type === likert - простая таблица
 * content.type === likert && content.subtype === 'mostleast' - таблица с уникальным ответом
 *
 * content.type === sort - drag & drop
 * content.type === rank - drag & drop c ранжированием
 * content.type === matching - drag & drop (multiple) сопоставление
 * content.type === range - слайдер
 * content.type === rank_range - два вопроса с линией радиокнопок
 *
 * content.type === media - картинка
 *
 * @property {string} content.text - html текст поля
 * @property {string} content.media - url картинки если есть
 * @property {Boolean} content.required - можно не отвечать
 * @property {string} content.placeholder - placeholder
 * @property {string} content.error_text - текст ошибки
 * @property {Object[]} content.variant - список вариантов
 * @property {string} content.variant[].code - code
 * @property {string} content.variant[].media - картинка вместо кругляшка
 * @property {string} content.variant[].text - текст варианта
 * @property {Object[]} content.rank_variant[] - список ранжирования вариантов
 * @property {string} content.rank_variant[].code - code
 * @property {string} content.rank_variant[].text - текст варианта
 * @property {string} content.rank_variant[].sort - порядок сортировки
 */

export default {
  name: 'Processing',

  directives: {
    Resize,
    Tooltip
  },

  components: {
    LWidget,
    ProcessingCheck,
    ProcessingContentBlocks,
    ProcessingContentTable,
    ProcessingCopyableLink,
    ProcessingHeader,
    ProcessingHtml,
    ProcessingInput,
    ProcessingLine,
    ProcessingLink,
    ProcessingMedia,
    ProcessingProcess51,
    ProcessingRequestButton,
    ProcessingRespondentInfoLine,
    ProcessingScreen360,
    ProcessingSubmit,
    RRestriction,
    VButton,
    VCard,
    VModal,
    VProgressLinear,
    WProgress,
    WTimer
  },

  mixins: [
    Frameable,
    ProcessingCheckScreenMixin,
    ProcessingDynamicContentMixin,
    ProcessingMenageSessionMixin
  ],

  provide () {
    return {
      sendData: this.sendData,
      sendDataAjax: this.sendDataAjax,
      service: this.service
    }
  },

  inject: ['global'],

  data () {
    /**
     * @property {object} api - относящиеся к API
     * @property {object} content - влияющие на отображаемый контент
     * @property {object} service - внутренние данные (передаются потомкам)
     * @property {object} sendData - отсылаемые данные (передаются потомкам)
     * @property {object} sendDataAjax - отсылаемые при асинхронной загрузке данные (передаются потомкам)
     */
    return {
      /**
       * @property {boolean} api.isAjax - чтобы не затирать sendData при ajax запросе
       * @property {object[] | null} api.responseData - приходящие данные
       * @property {boolean} api.tryReload - была ли повторная попытка загрузки при отказе
       */
      api: {
        isAjax: false,
        responseData: null,
        tryReload: false
      },
      /**
       * @property {string | null} content.header - заголовок
       * @property {boolean} content.isCard - информация обёрнута в VCard.vue или нет
       * @property {object[]} content.render - отображаемый контент
       * @property {object} content.widget - данные для виджетов
       * @property {object} content.widgetTop - данные для верхнего виджета
       */
      content: {
        header: null,
        isCard: true,
        render: [],
        widget: {
          isShown: false,
          progress: {
            current: null,
            total: null,
            text: ''
          },
          timer: {
            end: null,
            start: null
          }
        },
        widgetTop: {
          progress: {
            val: null
          }
        }
      },
      /**
       * @property {object} service.breakpoint - параметры контрольных точек ширины экрана
       * @property {object} service.error - параметры общих ошибок
       * @property {string | null} service.event - !!!DEPRECATED текущий event. Берётся из url (router/routes/main.js) !!!DEPRECATED использовать session.event
       * @property {function} service.getContentAjax - получение контента при "асинхронном" запросе
       * @property {object} service.restrictions - включение/отключение ограничений
       * @property {function} service.sendContent - отослать контент
       * @property {object} service.validation - параметры валидации
       * @property {object} service.modal - параметры модального окна
       */
      service: {
        submitButton: {
          isLoading: false
        },
        uuidSubmit: null,
        /**
         * @property {string | null} service.breakpoint.current - текущая контрольная точка
         * @property {object} service.breakpoint.all - все доступные контрольные точки
         */
        breakpoint: {
          current: null,
          all: {
            0: 'xs',
            576: 'sm',
            768: 'md',
            992: 'lg',
            1232: 'xl'
          }
        },
        /**
         * @property {Boolean} service.error.isShow - показывать ошибку?
         * @property {string} service.error.text - выводимый текст ошибки (type: error_text)
         */
        error: {
          isShow: false,
          text: this.$t('site.error.answerTheQuestion')
        },
        event: null, // оставлено для обратной совместимости, не использовать в дальнейшей разработке, использовать session
        getContentAjax: params => this.getContentAjax(params),
        getContent: () => this.getContent(),
        sendContent: params => this.sendContent(params),
        sendSubmitContent: params => this.sendSubmitContent(params),
        /**
         * @property {Boolean} service.restrictions.doTextCopy - отключение возможности выделять текст
         * @property {Boolean} service.restrictions.doPrint - отключение возможности распечатать
         * @property {Boolean} service.restrictions.doPrintScreen - отключение кнопки PrintScreen
         * @property {Boolean} service.restrictions.goBack - отключение кнопки назад в браузере
         * @property {Boolean} service.restrictions.goOffScreen - запрет прехода на другую вкладку
         * @property {Boolean} service.restrictions.showContextMenu - отключение показа контекстного меню
         * @property {Boolean} service.restrictions.showDevTools - отключение вызова DevTools
         */
        restrictions: {
          doTextCopy: !this.$root.isDev,
          doPrint: !this.$root.isDev,
          doPrintScreen: !this.$root.isDev,
          goBack: !this.$root.isDev,
          goOffScreen: !this.$root.isDev,
          showContextMenu: !this.$root.isDev,
          showDevTools: !this.$root.isDev
        },
        showRestrictionModal: false,
        /**
         * @property {object} service.validation.state - статус инпута в формате {[input id]: Boolean}
         * @property {string[]} service.validation.mustPass - все id инпутов на текущем экране
         */
        validation: {
          state: {},
          mustPass: [],
          filled: {}
        },
        /**
         * DEPRECATED выбодить через store
         * @property {object[]} service.modal.render - отображаемый в модальном окне контент (аналог content.render)
         * @property {function} service.modal.showModal - показать модальное окно
         * @property {function} service.modal.hideModal - скрыть модальное окно
         */
        modal: {
          render: [], // !не изменять напрямую!
          showModal: render => this.showModal(render),
          hideModal: () => this.hideModal()
        },
        unrendered: []
      },
      sendData: {},
      sendDataAjax: {}
    }
  },

  computed: {
    ...mapGetters({
      isModeExistByName: 'mode/isModeExistByName'
    }),

    /**
     * Необходимые модификаторы css классов
     * @return {object} - дополнительные классы
     */
    classes () {
      return {
        processing_type_card: this.content.isCard
      }
    },
    /**
     * Видимость модального окна
     * @return {Boolean} - показать модальное окно, или нет
     */
    isModalShown: {
      get () {
        return this.service.modal.render.length > 0
      },
      set (newValue) {
        if (!newValue) {
          this.clearModal()
        }
      }
    },
    clonedSendData () {
      return JSON.parse(JSON.stringify(this.sendData))
    }
  },

  watch: {
    /**
     * Действия при обновлении responseData
     * Выполняются после каждого ответа с сервера
     * @param {object[]} newData - новые данные
     */
    'api.responseData' (newData) {
      this.prepareDataHandler(newData)
    },

    /** Действия при обновлении sendData */
    clonedSendData () {
      this.service.error.isShow = false
    },

    /**
     * Блокирование кнопки на время выполнения запроса nsadc
     * @link https://ecopsy.atlassian.net/browse/LNKS-2500
     */
    PDC_data_loading () {
      if (this.PDC_data_loading && this.PDC_computed_isDynamic) {
        this.service.submitButton.isLoading = true
        showReloadMessage.start()
      } else {
        this.service.submitButton.isLoading = false
        showReloadMessage.stop()
      }
    },

    '$i18n.locale' () {
        showReloadMessage.setMessage(i18n.t('site.error.reloadPage'))
        this.service.error.text = this.$t('site.error.answerTheQuestion')
    }
  },

  mounted () {
    this.setBreakpoints()

    // Прослушка смены языка
    this.$store.subscribeAction((action) => {
      if (
        action.type === 'language/setCurrent' &&
        action.payload.reload
      ) {
        this.$nextTick(() => {
          this.getContent(true)
        })
      }
    })
  },

  methods: {
    ...mapActions({
      startLoading: 'loading/startLoading',
      stopLoading: 'loading/stopLoading',
      setTheme: 'theme/setTheme',
      setSupportLanguages: 'language/setSupported',
      showSnackbar: 'snackbar/showSnackbar'
    }),

    prepareDataHandler (unpreparedData) {
      this.PDC_data_cancelFetching = true

      this.prepareDataBefore()
      this.prepareData(unpreparedData)
      this.prepareDataAfter()

      this.$nextTick(() => {
        this.PDC_data_cancelFetching = false
        this.sendResizeMessage()
      })
    },

    prepareDataBefore () {
      this.clearUsedData()
    },

    prepareDataAfter () {
      // зачистка от нерабочих id
      if (!this.global.logo.url) {
        this.global.logo.setLogo({
          url: DEFAULT_LOGO_KEY
        })
      }

      Object.keys(this.service.validation.state).forEach(key => {
        if (!this.service.validation.mustPass.includes(key)) {
          delete this.service.validation.state[key]
          delete this.service.validation.filled[key]
          delete this.sendData[key]
        }
      })

      this.api.isAjax = false
    },

    prepareData (unpreparedData) {
      unpreparedData.forEach(data => {
        const { type, content, route } = data

        // не идёт в render и никак не обрабатывается
        if (route === 'modal') {
          this.service.unrendered.push(data)
          return
        }

        switch (type) {
          case 'logo':
            this.global.logo.setLogo({
              url: content.contentURL
            })
            break
          case 'title': // текст заголовка страницы
            document.title = content || this.$t('site.title')
            break
          case 'html': // текст в формате html
          case 'media': // картинка
          case 'copyable_link':
            if (!content) break
            this.content.render.push(data)
            break
          case 'submit': { // текст кнопки "Далее"
            let localContent = content

            if (getType(localContent) === 'string') {
              if (!localContent) break
              localContent = { text: content }
            }

            if (getType(localContent) === 'object') {
              if (!localContent.text) break
            }

            this.content.render.push({
              type: 'submit',
              content: {
                ...localContent,
                type: localContent.type || 'button'
              }
            })
            break
          }
          case 'error_text': // текст ошибки валидации
            this.service.error.text = content
            break
          case 'input': // различные экраны ввода (см. описание выше)
            if (content.answer && !this.sendData[content.code]) {
              this.$set(this.sendData, content.code, content.answer)
            } else if (!this.sendData[content.code]) {
              this.$set(this.sendData, content.code, [])
            }

            this.content.render.push(data)

            /**
             * Не вносить сюда required!
             * required тоже добавляются в валидацию со значением true
             * которое выставляется при проверке в конечном компоненте
             */
            if (!content.inactive) {
              this.service.validation.mustPass.push(`${content.code}`)
              this.setValidationById(content.code)
            }

            break
          case 'header': // текст заголовка экрана
            this.content.header = content
            break
          case 'timer': // инфа по таймеру в виджете
            this.content.widget.isShown = true
            this.content.widget.timer.start = content.start
            this.content.widget.timer.end = content.end
            this.content.widget.timer.current = content.current
            break
          case 'progress': // инфа по progressbar в виджете
            this.content.widget.isShown = true
            this.content.widget.progress.total = content.in
            this.content.widget.progress.current = content.val
            this.content.widget.progress.text = content.text
            break
          case 'subscreen': // TODO DEPRECATED используется при ajax, добавляет подстроки
            if (content.content.length > 0) {
              this.prepareData(content.content)
            }
            break
          case 'scroll': // если принудительно надо проскроллить вверх/вниз
            if (!content || this.api.isAjax) break
            this.scrollToHandler(content)
            break
          case 'restriction': // добавить ограничения
            if (!content) break
            this.service.restrictions = { ...this.service.restrictions, ...content }
            break
          case 'check': {
            this.PDC_method_addContent(content || {})
            break
          }
          case 'content_table': // экран информации по прохождению тестов
          case 'request_button': // evraz lk
          case 'screen360':
          case 'respondent_info_line': // evraz lk
          case 'content_blocks': // evraz lk
          case 'line': // evraz lk
            this.content.isCard = false
            this.content.render.push(data)
            break
          // todo бэк сломал логику
          case 'link_to_other_session':
            this.content.render.push({ type: 'link', content: data })
            break
          // todo #mode различные модификации
          case 'newFront':
            this.addModes([type])
            break
          case 'screenBlank':
            this.addModes([type])
            this.removeRestrictions()
            break
          case 'proctoring': { // проверка прокторинга
            this.checkProctoring(content)
            break
          }
          case 'fio': {
            this.global.user.displayName = content
            break
          }
          case 'top_bar': {
            this.content.widgetTop.progress.val = content.val

            break
          }
          case 'lang': {
            if (content.supported) {
              this.setSupportLanguages(content.supported)
            }
            break
          }
          case 'theme': {
            this.setTheme(content)
            break
          }
          case 'style': {
            this.setStyles(content)
            break
          }
          case 'background_img': {
            this.setBackground(content)
            break
          }
          case 'dynamic': {
            this.PDC_method_addCode(data.dynamicCode)
            break
          }
          case 'stop_marker': {
            if (this.isModeExistByName('frame')) {
              this.frameable_SendPostMessageToParent({
                type: 'stop_marker'
              })
            }
            break
          }
          case 'load_id': // todo не назначено действие
          case 'email': // todo не назначено действие
            break
          default:
            console.warn(`Типу ${type} не назначено действие!`)
        }
      })
    },

    /**
     * Проверка iframe прокторинга
     * @param {string} proctoringUrl - URL для редиретка на прокторинг
     */
    checkProctoring (proctoringUrl) {
      const origin = new URL(location.href).searchParams.get('examus-client-origin')
      const inIframe = () => {
        try {
          return window.self !== window.top
        } catch (e) {
          return true
        }
      }

      // тест внутри прокторинга - успех
      if (!!origin && inIframe()) return

      // тест не внутри прокторинга - ошибка
      if (proctoringUrl) window.location.replace(proctoringUrl)
    },

    removeRestrictions () {
      Object
        .keys(this.service.restrictions)
        .forEach(key => {
          this.service.restrictions[key] = false
        })
    },

    prepareModalData (unpreparedData) {
      unpreparedData.forEach(data => {
        const { type, content } = data

        switch (type) {
          case 'title': // текст заголовка страницы
            break
          case 'header': // текст заголовка
          case 'html': // текст в формате html
          case 'input':
            if (!content) break

            this.service.modal.render.push(data)
            break
          case 'submit': // текст кнопки "Далее"
            if (!content) break

            this.service.modal.render.push({
              type: 'submit',
              content: {
                text: content,
                type: 'modal'
              }
            })
            break
          default:
            console.warn(`Типу ${type} не назначено действие в модальном окне`)
        }
      })
    },

    /**
     * Добавление валидации по id инпута
     * @param {number | string} id - добавляемый id
     */
    setValidationById (id) {
      if (this.service.validation.state[id] === undefined) {
        this.$set(this.service.validation.state, id, false)
        this.$set(this.service.validation.filled, id, false)
      }
    },

    /**
     * Полечение контента вопросов
     * @param {boolean=false} savePreviousAnswers - необходимость оставлять выбранные значение (например при сене языка)
     * @return {Promise<AxiosResponse<any>>}
     */
    getContent (savePreviousAnswers = false) {
      const sendData = convertToFormData({
        session: this.getSessionUUID()
      })

      this.startLoading()

      return getContentApi(sendData)
        .then(
          response => {
            this.api.isAjax = savePreviousAnswers
            this.api.responseData = response.data
            this.service.showRestrictionModal = true
          },
          error => {
            if (
              error?.response?.status === 404 ||
              error?.response?.data?.error === 'session'
            ) {
              this.clearSession()

              if (!this.api.tryReload) {
                this.api.tryReload = true
                this.setSession()
              }
            }
          })
        .then(() => {
          if (this.isModeExistByName('frame')) {
            this.frameable_SendPostMessageToParent({
              type: 'scroll'
            })
          }

          this.stopLoading()
        })
    },

    /**
     * TODO DEPRECATED
     * @link https://ecopsy.atlassian.net/browse/LNKS-1735
     *
     * Получение контента при "асинхронном" запросе
     * @param {Object} params - объект дополнительных данных
     */
    getContentAjax (params) {
      const sendData = convertToFormData({
        session: this.getSessionUUID(),
        ...this.sendDataAjax,
        ...params
      })

      // Для обратной совместимости с динамическим контентом
      this.PDC_data_cancelFetching = true

      getContentAjaxApi(sendData)
        .then(
          response => {
            this.api.isAjax = true
            this.api.responseData = response.data
          },
          error => {
            if (
              error?.response?.status === 404 ||
              error?.response?.data?.error === 'session'
            ) {
              this.clearSession()

              if (!this.api.tryReload) {
                this.api.tryReload = true
                this.setSession()
              }
            }
          })
        .then(() => {
          this.PDC_data_cancelFetching = false
        })
    },

    sendSubmitContent (params) {
      if (this.service.uuidSubmit) {
        params = {
          submit_uuid: this.service.uuidSubmit,
          ...params
        }
      }

      this.sendContent(params)
    },

    /**
     * Отправка ответа
     * @param {Object} params - объект дополнительных данных
     * @example sendContent({ time_out: true})
     */
    sendContent (params) {
      const isValid = Object.values(this.sendData)
        .every(value => getType(value) === 'array' && !value.includes(undefined))

      if (!isValid) {
        this.showSnackbar({
          content: this.$t('site.error.reloadPage')
        })
        return false
      }

      const sendData = convertToFormData({
        session: this.getSessionUUID(),
        ...this.sendData,
        ...params
      })

      this.startLoading()

      sendContentApi(sendData)
        .then(
          () => this.getContent(),
          error => {
            if ('error' in error.response.data) {
              if (error.response.data.error === 'session') {
                this.clearSession()
              }
            }
          })
        .then(() => {
          this.stopLoading()
        })
    },

    /**
     * Очистка используемых данных
     */
    clearUsedData () {
      const sendDataKeys = Object.keys(this.sendData)
      const sendDataAjaxKeys = Object.keys(this.sendDataAjax)

      const clearDataByKeys = (keys) => keys.forEach(key => {
        delete this.sendData[key]
        delete this.service.validation.state[key]
        delete this.service.validation.filled[key]
      })

      if (this.api.isAjax) {
        sendDataAjaxKeys.forEach(key => {
          delete this.sendDataAjax[key]
        })
      } else {
        clearDataByKeys(sendDataKeys)
        this.service.error.text = this.$t('site.error.answerTheQuestion')
      }

      this.clearModal()
      this.PDC_method_clearCodes()

      this.service.error.isShow = false
      this.service.validation.mustPass = []
      this.service.unrendered = []

      this.content.header = null
      this.content.isCard = true
      this.content.render = []
      this.content.widget.isShown = false
      this.content.widget.progress.total = null
      this.content.widget.progress.current = null
      this.content.widget.progress.text = ''
      this.content.widget.timer.start = null
      this.content.widget.timer.current = null
      this.content.widget.timer.end = null
      this.content.widgetTop.progress.val = null
    },

    /**
     * Принудительно пролистать в начало/в конец экрана
     * @param {string} direction - направление куда листать
     */
    scrollToHandler (direction) {
      if (direction === 'top') {
        window.scrollTo(0, 0)
      }

      if (direction === 'bottom') {
        // fixme непонятно как чекать что всё действительно отрендерено
        setTimeout(() => {
          const scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
          )

          window.scrollTo(0, scrollHeight)
        }, 100)
      }
    },

    showModal (unpreparedData) {
      this.clearModal()
      this.prepareModalData(unpreparedData)
      this.sendResizeMessage()
    },

    sendResizeMessage () {
      if (!this.isModeExistByName('frame')) return

      this.$nextTick(() => {
        const modalHeight = document.querySelector('.v-modal__content')?.clientHeight + 150 || 0
        const documentHeight = document.body.scrollHeight + 5

        this.frameable_SendPostMessageToParent({
          docHeight: documentHeight < modalHeight
            ? modalHeight
            : documentHeight
        })
      })
    },

    hideModal () {
      this.clearModal()
      this.sendResizeMessage()
    },

    clearModal () {
      this.service.modal.render = []
    },

    /**
     * Уствновка контрольных точек
     */
    setBreakpoints () {
      const currentBreakpoint = Object.keys(this.service.breakpoint.all)
        .reverse()
        .find(bp => bp < window.innerWidth)
      this.service.breakpoint.current = this.service.breakpoint.all[currentBreakpoint || 0]
    },

    /**
     * Действия при ресайзе основного контейнера
     */
    onResize () {
      this.setBreakpoints()
      this.sendResizeMessage()
    },

    setStyles (content) {
      const id = 'custom-root-styles'
      const element = document.getElementById(id)
      const rootStyles = Object
        .entries(content)
        .map(([name, value]) => {
          const colorIndex = name.indexOf('color')
          const colorRgbIndex = name.indexOf('color-rgb')
          let rule = `--${name}:${value}`

          /**
           * К каждому цвету добавляется rgb двойник:
           * --color-border: #f48b7c;
           * --color-rgb-border: 244,139,124;
           */
          if (colorIndex !== -1 && colorRgbIndex === -1) {
            rule = `${rule};--${name.slice(0, colorIndex)}color-rgb${name.slice(colorIndex + 5)}:${convertHexToRgb(
              value)}`
          }

          return rule
        })
        .join(';')

      if (element) {
        element.remove()
      }

      document.head.insertAdjacentHTML('beforeend', `<style id="${id}">html:root {${rootStyles}}</style>`)
    },

    setBackground (content) {
      if (content.backgroundURL) {
        this.global.background.setBackgroundUrl(content.backgroundURL)
      }
    },

    convertSnakeToCamel,
    capitalize,
    debounce
  }
}
</script>

<style lang="scss">
@import "~@styles/variables/index.scss";
@import "~@styles/tools/index.scss";
@import "~bootstrap/scss/mixins/breakpoints";

.processing {
  position: relative;

  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: flex-start;
  }

  &__content {
    flex-basis: 0;
    flex-grow: 1;
    width: auto;
  }

  &__widget {
    top: 1rem;
    position: sticky;
    z-index: 5;
    order: 1;
    flex: 0 0 auto;
    margin-bottom: 1rem;

    @include media-breakpoint-up(xl) {
      order: 2;
      width: 13rem;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }

  &_type {
    &_card {
      .processing__content {
        width: 100%;
        padding: 1.5rem;
        background-color: rgba(var(--processing-content-bg-color-rgb), var(--processing-content-bg-opacity));
        box-shadow: var(--processing-content-shadow);
        border-radius: var(--processing-content-border-radius);
        border: var(--processing-content-border);

        @include media-breakpoint-down(lg) {
          padding-right: 1rem;
          padding-left: 1rem;
        }

        @include media-breakpoint-down(sm) {
          padding: 1rem;
        }
      }
    }
  }
}
</style>
